<template>
  <v-container id="widgets" fluid>
    <!-- <base-v-component link="components/calendars" /> -->
    <v-row>
      <!-- {{table}}
      {{gap}}
    
     -->

      <!-- {{booking}} -->
      <!-- {{booking}}
      {{}} -->

      <v-col cols="12" md="3">
        <v-row class="ml-3">
          <h2>Customer</h2>
        </v-row>
        <v-card height="760px">
          <v-row class="mx-1 mr-2">
            <v-col cols="12">
              <v-text-field
                v-model="searchCustomer"
                label="Search..."
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="3">
        <v-btn outlined  color="grey" small>
        <v-icon>mdi-plus</v-icon>
        </v-btn>   
      </v-col> -->
          </v-row>
          <v-list two-line class="overflow">
            <v-list-item-group
              v-model="selected"
              active-class="primary--text"
              mandatory
            >
              <template>
                <v-list-item
                  v-for="(item, index) in customer"
                  :key="index"
                  :value="item.customer_id"
                >
                  <template>
                    <v-list-item-avatar>
                      <v-avatar color="#2196F3" size="56" class="white--text">
                        {{ item.name[0] }}
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-row class="ml-3">
          <h2>Customer Details</h2>
        </v-row>
        <v-row class="px-8">
          <v-col cols="12">
            <v-text-field
              v-model="customerName"
              outlined
              label="Name"
              clearable
              :error-messages="customerNameErrors"
              required
              @input="$v.customerName.$touch()"
              @blur="$v.customerName.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="customerContact"
              outlined
              label="Contact"
              clearable
              :error-messages="customerContactErrors"
              required
              @input="$v.customerContact.$touch()"
              @blur="$v.customerContact.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="5">
            <v-menu
              v-model="birthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthday"
                  label="Birthday(optional)"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="birthday"
                no-title
                @click="birthdayMenu = false"
                dark
                color="secondary"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="customerEmail"
              outlined
              label="Email"
              clearable
              :error-messages="customerEmailErrors"
              required
              @input="$v.customerEmail.$touch()"
              @blur="$v.customerEmail.$touch()"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="address"
              clearable
              outlined
              clear-icon="mdi-close"
              label="Address(optional)"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mx-auto">
            <v-btn
              color="secondary"
              class="white--text"
              @click="updateCustomerDetail()"
            >
              Save
            </v-btn>
            <v-snackbar v-model="snackbar" color="primary" :timeout="timeout">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="secondary"
                  text
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>

            <v-btn
              color="secondary"
              outlined
              @click.stop="confirmDeleteDialog = true"
            >
              Delete
            </v-btn>
            <v-dialog v-model="confirmDeleteDialog" max-width="290">
              <v-card>
                <v-card-title> Are you confirm to delete? </v-card-title>

                <v-card-text>
                  <span class="font-weight-medium"
                    >All the appointment and customer detail will be delete
                    too</span
                  >
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="confirmDeleteDialog = false"
                  >
                    cancel
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteCustomerAndBooking()"
                  >
                    Agree
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row class="ml-3">
          <h2>Appointment</h2>
        </v-row>
        <v-card color="grey lighten-4" v-if="appointmentList != ''">
          <v-row>
            <v-col class="px-8">
              <v-list two-line color="grey lighten-4">
                <template>
                  <v-list-item
                    v-for="(item, index) in appointmentList"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-if="industry == 'F&B'"
                        ><b class="font-weight-black">Total Person: </b
                        ><span class="font-weight-medium">{{
                          item.person
                        }}</span></v-list-item-title
                      >
                      <v-list-item-title v-if="industry != 'F&B'"
                        ><b class="font-weight-black">Service: </b
                        ><span class="font-weight-medium">{{
                          item.service_title
                        }}</span></v-list-item-title
                      >
                      <v-list-item-title v-if="industry != 'F&B'"
                        ><b class="font-weight-black">Provider: </b
                        ><span class="font-weight-medium">{{
                          item.provider_name
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle>
                        <b class="font-weight-black"
                          >{{ item.selected_date }} {{ item.selected_time }}</b
                        ></v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        ><b class="font-weight-bold"
                          >Branch: {{ item.name }}</b
                        ></v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        ><b class="font-weight-bold"
                          >Note: {{ item.remark }}</b
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-dialog
                        v-model="dialog"
                        width="500"
                        persistent
                        :retain-focus="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            icon
                            color="primary"
                            v-model="selectedAppointment"
                            @click="
                              (selectedAppointment = item.booking_id),
                                getAppointmentForUpdate()
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card v-if="industry != 'F&B'">
                            <v-toolbar color="primary" dark
                              >Edit appointment</v-toolbar
                            >
                            <v-card-text>
                              <v-alert
                                class="mt-3"
                                dense
                                outlined
                                type="error"
                                v-if="incompleteInfo"
                              >
                                Please <strong>complete</strong> all the
                                information needed
                              </v-alert>
                              <v-row class="mt-2">
                                <v-col cols="12">
                                  <v-select
                                    :items="branchItem"
                                    v-model="editBranch"
                                    item-text="name"
                                    item-value="branch_id"
                                    label="Branch"
                                    prepend-icon="mdi-store"
                                    @change="
                                      getBranchWorkDayAndTime(),
                                        (editService = ''),
                                        (editProvider = ''),
                                        (editDate = ''),
                                        (serviceDuration = ''),
                                        (providerBreakTime = []),
                                        (providerWorkDay = []),
                                        (providerWorkTime = []),
                                        (slot = '')
                                    "
                                  ></v-select>
                                </v-col>

                                <v-col cols="12">
                                  <v-select
                                    :items="serviceItem"
                                    v-model="editService"
                                    item-text="title"
                                    item-value="service_id"
                                    label="Service"
                                    prepend-icon="mdi-toolbox"
                                    @change="
                                      getServiceInfo(),
                                        (editProvider = ''),
                                        (editDate = ''),
                                        (providerBreakTime = []),
                                        (providerWorkDay = []),
                                        (providerWorkTime = []),
                                        (slot = '')
                                    "
                                    v-if="editBranch != ''"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    :items="providerItem"
                                    v-model="editProvider"
                                    item-text="name"
                                    item-value="provider_id"
                                    label="Provider"
                                    prepend-icon="mdi-account-multiple"
                                    @click="
                                      (calendar = false), (editProvider = '')
                                    "
                                    @change="
                                      getSelectedProviderForEdit(),
                                        (calendar = true),
                                        (editDate = ''),
                                        (editTime = '')
                                    "
                                    v-if="editBranch && editService"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="
                                      editBranch &&
                                      editService &&
                                      editProvider &&
                                      calendar
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editDate"
                                        label="Choose your date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <FunctionalCalendar
                                      v-model="editCustomerDate"
                                      :limits="{
                                        min: today,
                                        max: '01/01/3000',
                                      }"
                                      :disabledDates="branchHoliday"
                                      :disabled-day-names="weekdays"
                                      :hidden-elements="['leftAndRightDays']"
                                      :is-date-picker="true"
                                      v-on:choseDay="
                                        editDate =
                                          editCustomerDate.selectedDate;
                                        getBooking();
                                        editTime = '';
                                      "
                                    >
                                    </FunctionalCalendar>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    :items="timesPlusDuration"
                                    v-model="editTime"
                                    label="Pick your time"
                                    prepend-icon="mdi-clock"
                                    single-line
                                    v-if="
                                      editDate &&
                                      editProvider &&
                                      editService &&
                                      calendar
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    v-model="editRemark"
                                    label="Customer Remark"
                                    rows="5"
                                    prepend-icon="mdi-comment"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                color="secondary"
                                class="white--text"
                                @click="
                                  (dialog.value = false),
                                    (selectedAppointment = ''),
                                    (appointmentID = ''),
                                    (incompleteInfo = false)
                                "
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="secondary"
                                class="white--text"
                                text
                                @click="editAppointment()"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>

                          <v-card v-if="industry == 'F&B'">
                            <v-toolbar color="primary" dark
                              >Edit appointment</v-toolbar
                            >
                            <v-card-text>
                              <v-alert
                                class="mt-3"
                                dense
                                outlined
                                type="error"
                                v-if="incompleteInfo"
                              >
                                Please <strong>complete</strong> all the
                                information needed
                              </v-alert>
                              <v-row class="mt-2">
                                <v-col cols="12">
                                  <v-select
                                    :items="branchItem"
                                    v-model="editBranch"
                                    item-text="name"
                                    item-value="branch_id"
                                    label="Branch"
                                    prepend-icon="mdi-store"
                                    @change="
                                      getBranchWorkDayAndTime(),
                                        (editPerson = ''),
                                        (editDate = ''),
                                        (slot = '')
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editPerson"
                                    label="Total Person"
                                    prepend-icon="mdi-account"
                                    @click="calendar = false"
                                    @input="
                                      (calendar = true),
                                        (editDate = ''),
                                        $v.editPerson.$touch()
                                    "
                                    :error-messages="editPersonErrors"
                                    required
                                    @blur="$v.editPerson.$touch()"
                                    v-if="editBranch"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="
                                      editBranch &&
                                      editPerson &&
                                      calendar &&
                                      !$v.editPerson.$invalid
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editDate"
                                        label="Choose your date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <FunctionalCalendar
                                      v-model="editCustomerDate"
                                      :limits="{
                                        min: today,
                                        max: '01/01/3000',
                                      }"
                                      :disabledDates="branchHoliday"
                                      :disabled-day-names="weekdays"
                                      :hidden-elements="['leftAndRightDays']"
                                      :is-date-picker="true"
                                      v-on:choseDay="
                                        editDate =
                                          editCustomerDate.selectedDate;
                                        getBooking();
                                        editTime = '';
                                      "
                                    >
                                    </FunctionalCalendar>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    :items="timesPlusDuration"
                                    v-model="editTime"
                                    label="Pick your time"
                                    prepend-icon="mdi-clock"
                                    single-line
                                    v-if="editDate && editPerson && calendar"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    v-model="editRemark"
                                    label="Customer Remark"
                                    rows="5"
                                    prepend-icon="mdi-comment"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                color="secondary"
                                class="white--text"
                                @click="
                                  (dialog.value = false),
                                    (selectedAppointment = ''),
                                    (appointmentID = ''),
                                    (incompleteInfo = false)
                                "
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="secondary"
                                class="white--text"
                                text
                                @click="editAppointment()"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>

                  <!-- <v-divider
            v-if="index < appointmentList.length - 1"
            :key="index"
          ></v-divider> -->
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <modal @update="updateCustomerList"></modal>
  </v-container>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { FunctionalCalendar } from "vue-functional-calendar";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import modal from "../dashboard/component/modal";

export default {
  name: "Customer",

  mixins: [validationMixin],

  validations: {
    customerName: { required },
    customerContact: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    customerEmail: { required, email },

    // birthday: {
    //   required,
    // },

    // address: {
    //   required,
    // },

    editPerson: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue(value) {
        return maxValue(this.maxPerson)(value);
      },
    },
  },

  components: {
    FunctionalCalendar,
    modal,
  },

  data: () => ({
    domain: BASEURL,
    color: "#2196F3",
    appointmentList: [],
    company_id: "",
    customer: [],
    selected: "",
    customerName: "",
    customerContact: "",
    customerEmail: "",
    searchCustomer: "",
    snackbar: false,
    text: "",
    timeout: 2000,
    confirmDeleteDialog: false,
    selectedAppointment: "",
    dialog: "",
    menu: false,
    date: "",
    editBranch: "",
    editPerson: "",
    editDate: "",
    editTime: "",
    editRemark: "",
    editCustomerDate: {},
    branchItem: [],
    maxPerson: "",
    branchHoliday: "",
    branchWorkingTime: "",
    workingDays: "",
    gap: "",
    table: "",
    serviceDuration: "",
    serviceID: "",
    slot: "",
    timesPlusDuration: [],
    booking: "",
    industry: "",
    serviceItem: [],
    providerItem: [],
    editService: "",
    editProvider: "",
    providerWorkDay: [],
    providerWorkTime: [],
    providerBreakTime: [],
    appointmentID: "",
    calendar: true,
    serviceTitle: "",
    serviceDescription: "",
    incompleteInfo: false,
    birthdayMenu: false,
    address: "",
    birthday: "",
  }),
  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
    this.industry = data.industry;
    this.getAllCustomer();
    this.getCompanyBranch();
  },

  watch: {
    selected() {
      this.getCustomerDetail();
      this.getCustomerAppointment();
    },
    searchCustomer() {
      this.searchCustomerByName();
    },
    editBranch() {
      if (this.industry != "F&B") {
        this.getBranchWorkDayAndTime();
        this.getAppointmentForUpdate();

        this.getAllServiceForEdit();
        this.getAllProviderForEdit();
      } else {
        this.getBranchWorkDayAndTime();
        this.getAppointmentForUpdate();
        this.findMaxPerson();
        this.getService();
      }
    },
    editService() {
      if (this.industry != "F&B") {
        this.getAllProviderForEdit();
      }
    },
    editPerson() {
      if (this.industry == "F&B") {
        this.getService();
      }
    },
  },
  computed: {
    editPersonErrors() {
      const errors = [];
      if (!this.$v.editPerson.$dirty) return errors;
      !this.$v.editPerson.numeric &&
        errors.push("Person must consist only number");
      !this.$v.editPerson.minValue && errors.push("At least 1 person");
      !this.$v.editPerson.maxValue &&
        errors.push("Maximum " + this.maxPerson + " Person ");
      !this.$v.editPerson.required && errors.push("Person is required");
      return errors;
    },
    customerNameErrors() {
      const errors = [];
      if (!this.$v.customerName.$dirty) return errors;
      !this.$v.customerName.required &&
        errors.push("Customer name is required");
      return errors;
    },
    customerContactErrors() {
      const errors = [];
      if (!this.$v.customerContact.$dirty) return errors;
      !this.$v.customerContact.numeric &&
        errors.push("Phone must consist only number");
      !this.$v.customerContact.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.customerContact.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.customerContact.required && errors.push("Phone is required");
      return errors;
    },
    customerEmailErrors() {
      const errors = [];
      if (!this.$v.customerEmail.$dirty) return errors;
      !this.$v.customerEmail.email && errors.push("Must be valid e-mail");
      !this.$v.customerEmail.required && errors.push("E-mail is required");
      return errors;
    },

    // addressErrors() {
    //   const errors = [];
    //   if (!this.$v.address.$dirty) return errors;
    //   !this.$v.address.required && errors.push("Address is required");
    //   return errors;
    // },
    // birthdayErrors() {
    //   const errors = [];
    //   if (!this.$v.birthday.$dirty) return errors;
    //   !this.$v.birthday.required && errors.push("Birthday is required");
    //   return errors;
    // },
    today() {
      var d = new Date();
      var datestring =
        ("0" + d.getDate()).slice(-2) +
        "/" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        d.getFullYear();
      return datestring;
    },
    weekdays() {
      var value = [];
      if (this.workingDays[0] == 1 || this.providerWorkDay[0] == 1) {
        value.push("Su");
      }
      if (this.workingDays[1] == 1 || this.providerWorkDay[1] == 1) {
        value.push("Mo");
      }
      if (this.workingDays[2] == 1 || this.providerWorkDay[2] == 1) {
        value.push("Tu");
      }
      if (this.workingDays[3] == 1 || this.providerWorkDay[3] == 1) {
        value.push("We");
      }
      if (this.workingDays[4] == 1 || this.providerWorkDay[4] == 1) {
        value.push("Th");
      }
      if (this.workingDays[5] == 1 || this.providerWorkDay[5] == 1) {
        value.push("Fr");
      }
      if (this.workingDays[6] == 1 || this.providerWorkDay[6] == 1) {
        value.push("Sa");
      }

      return value;
    },
    timeSession() {
      var moment = require("moment"); // require
      moment().format();
      var currentTime = moment(new Date(), "hmm").format("HH:mm");
      var currentDay = moment(new Date(), "ddmmyy").format("D/M/YYYY");
      var startTime = this.branchWorkingTime[0];
      var interval = this.gap;
      var times = [];
      var period = "m";
      var periodsInADay = moment.duration(1, "day").as(period);
      var startTimeMoment = moment(startTime, "HH:mm");
      var endTime = this.branchWorkingTime[1];
      var endTimeMoment = moment(endTime, "HH:mm");

      for (let i = 0; i <= periodsInADay; i++) {
        var time = startTimeMoment.add(i === 0 ? 0 : interval, period);
        if (this.selectedDate == currentDay) {
          if (time.format("HH:mm") > currentTime && time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        } else {
          if (time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        }
      }

      return times;
    },
    timeSessionForServiceType() {
      var moment = require("moment"); // require
      moment().format();
      var currentTime = moment(new Date(), "hmm").format("HH:mm");
      var currentDay = moment(new Date(), "ddmmyy").format("D/M/YYYY");
      var startTime = "";
      var endTime = "";
      var breakStartTime = this.providerBreakTime[0];
      var breakEndTime = this.providerBreakTime[1];
      if (this.selectedProvider != "") {
        startTime = this.providerWorkTime[0];
        endTime = this.providerWorkTime[1];
      } else {
        startTime = this.start;
        endTime = this.end;
      }
      var breakStartTimeMoment = moment(breakStartTime, "HH:mm");
      var breakEndTimeMoment = moment(breakEndTime, "HH:mm");

      var interval = this.gap;
      var firstSession = [];
      var secondSession = [];
      var times = [];
      var period = "m";
      var periodsInADay = moment.duration(1, "day").as(period);
      var startTimeMoment = moment(startTime, "HH:mm");
      var endTimeMoment = moment(endTime, "HH:mm");

      for (let i = 0; i <= periodsInADay; i += interval) {
        var time = startTimeMoment.add(i === 0 ? 0 : interval, period);

        if (this.selectedDate == currentDay) {
          if (time.format("HH:mm") > currentTime && time <= endTimeMoment) {
            if (time <= breakStartTimeMoment) {
              firstSession.push(time.format("HH:mm"));
            }
            if (time >= breakEndTimeMoment) {
              secondSession.push(time.format("HH:mm"));
            }
            times = firstSession.concat(secondSession);
          }
        } else {
          if (time <= endTimeMoment) {
            if (time < breakStartTimeMoment) {
              firstSession.push(time.format("HH:mm"));
            }
            if (time >= breakEndTimeMoment) {
              secondSession.push(time.format("HH:mm"));
            }
            times = firstSession.concat(secondSession);
          }
        }
      }

      return times;
    },
  },
  methods: {
    getAllCustomer() {
      const params = new URLSearchParams();
      params.append("getAllCustomer", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.customer = response.data.customer;
          } else {
            console.log("no customer found on this company");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchCustomerByName() {
      const params = new URLSearchParams();
      params.append("searchCustomerByName", "done");
      params.append("company_id", this.company_id);
      params.append("search_customer", this.searchCustomer);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.customer = response.data.customer;
          } else {
            console.log("no customer found on this company");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCustomerDetail() {
      this.$v.$reset();
      for (let i = 0; i < this.customer.length; i++) {
        if (this.customer[i].customer_id == this.selected) {
          this.customerName = this.customer[i].name;
          this.customerContact = this.customer[i].contact;
          this.customerEmail = this.customer[i].email;
          this.birthday = this.customer[i].birthday;
          this.address = this.customer[i].address;
        }
      }
    },

    getCustomerAppointment() {
      if (this.industry == "F&B") {
        const params = new URLSearchParams();
        params.append("getCustomerAppointment", "done");
        params.append("customer_id", this.selected);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.appointmentList = response.data.booking;
            } else {
              console.log("no appointment found on this customer ");
              this.appointmentList = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("getCustomerAppointmentForService", "done");
        params.append("customer_id", this.selected);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.appointmentList = response.data.booking;
            } else {
              console.log("no appointment found on this customer ");
              this.appointmentList = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    updateCustomerDetail() {
      this.$v.$touch();
      if (
        !this.$v.customerName.$invalid &&
        !this.$v.customerContact.$invalid &&
        !this.$v.customerEmail.$invalid
        // !this.$v.address.$invalid &&
        // !this.$v.birthday.$invalid
      ) {
        const params = new URLSearchParams();
        params.append("update", "done");
        params.append("customer_id", this.selected);
        params.append("name", this.customerName);
        params.append("contact", this.customerContact);
        params.append("email", this.customerEmail);
        params.append("address", this.address);
        params.append("birthday", this.birthday);

        axios({
          method: "post",
          url: this.domain + "/customer/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.text = "Update Successful";
              this.getAllCustomer();
            } else {
              console.log("update fail");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    deleteCustomerAndBooking() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("customer_id", this.selected);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.confirmDeleteDialog = false;
            this.snackbar = true;
            this.text = "Delete Successful";
            this.getAllCustomer();
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editAppointment() {
      if (this.industry == "F&B") {
        if (
          this.editBranch == "" ||
          this.editPerson == "" ||
          this.editDate == "" ||
          this.editTime == ""
        ) {
          this.incompleteInfo = true;
          return;
        }
        const params = new URLSearchParams();
        params.append("updateBookingForF&B", "done");
        params.append("booking_id", this.appointmentID);
        params.append("service_id", this.serviceID);
        params.append("person", this.editPerson);
        params.append("duration", this.serviceDuration);
        params.append("branch_id", this.editBranch);
        params.append("selected_date", this.editDate);
        params.append("selected_time", this.editTime);
        params.append("service_title", this.serviceTitle);
        params.append("service_description", this.serviceDescription);
        params.append("remark", this.editRemark);
        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.incompleteInfo = false;
              this.snackbar = true;
              this.text = "Successfuly Edit";
              this.getCustomerAppointment();
            } else {
              this.snackbar = true;
              this.text = "fail to Edit... Try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (
          this.editBranch == "" ||
          this.editService == "" ||
          this.editProvider == "" ||
          this.editDate == "" ||
          this.editTime == ""
        ) {
          this.incompleteInfo = true;
          return;
        }
        const params = new URLSearchParams();
        params.append("updateBookingForService", "done");
        params.append("booking_id", this.appointmentID);
        params.append("service_id", this.editService);
        params.append("provider_id", this.editProvider);
        params.append("duration", this.serviceDuration);
        params.append("branch_id", this.editBranch);
        params.append("selected_date", this.editDate);
        params.append("selected_time", this.editTime);
        params.append("service_title", this.serviceTitle);
        params.append("service_description", this.serviceDescription);
        params.append("remark", this.editRemark);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.incompleteInfo = false;
              this.snackbar = true;
              this.text = "Successfuly Edit";
              this.getCustomerAppointment();
            } else {
              this.snackbar = true;
              this.text = "fail to Edit... Try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getHolidayDayTime", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchItem = response.data.branch;
            this.getBranchWorkDayAndTime();
          } else {
            console.log("no branch find");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findMaxPerson() {
      const params = new URLSearchParams();
      params.append("findMaxPerson", "done");
      params.append("branch_id", this.editBranch);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.maxPerson = response.data.service[0].seat;
            console.log("get max seat");
          } else {
            console.log("Booking failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranchWorkDayAndTime() {
      for (var i = 0; i < this.branchItem.length; i++) {
        if (this.branchItem[i].branch_id === this.editBranch) {
          this.workingDays = JSON.parse(this.branchItem[i].working_day);
          this.branchWorkingTime = JSON.parse(this.branchItem[i].working_time);
          this.gap = JSON.parse(this.branchItem[i].gap);
          this.branchHoliday = JSON.parse(this.branchItem[i].date);
        }
      }
    },

    getService() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.editBranch);
      params.append("seat", this.editPerson);
      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.table = response.data.service[0];
            this.serviceID = this.table.service_id;
            this.serviceDuration = this.table.duration;
            this.serviceTitle = this.table.title;
            this.serviceDescription = this.table.description;
            this.slot = this.table.slot;
            this.getBooking();
          } else {
            console.log("no service");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBooking() {
      if (this.industry == "F&B") {
        const params = new URLSearchParams();
        params.append("check", "done");
        params.append("selected_date", this.editDate);
        params.append("service_id", this.serviceID);
        params.append("booking_id", this.appointmentID);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("checkForService", "done");
        params.append("selected_date", this.editDate);
        params.append("provider_id", this.editProvider);
        params.append("booking_id", this.appointmentID);
        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getAppointmentForUpdate() {
      for (var i = 0; i < this.appointmentList.length; i++) {
        if (this.appointmentList[i].booking_id == this.selectedAppointment) {
          this.editService = this.appointmentList[i].service_id;
          this.editBranch = this.appointmentList[i].branch_id;
          this.editPerson = this.appointmentList[i].person;
          this.editDate = this.appointmentList[i].selected_date;
          this.editTime = this.appointmentList[i].selected_time;
          this.editRemark = this.appointmentList[i].remark;
          this.editProvider = this.appointmentList[i].provider_id;
          this.appointmentID = this.appointmentList[i].booking_id;
        }
      }
    },
    finalTime() {
      var moment = require("moment"); // require
      moment().format();
      var duration = this.serviceDuration;
      this.timesPlusDuration = [];

      var first = [];

      if (this.industry == "F&B") {
        first = this.timeSession;
      } else {
        first = this.timeSessionForServiceType;
      }
      var period = "m";
      // var second = [];
      for (let i = 0; i < first.length; i++) {
        var start = moment(first[i], "HH:mm");
        var s = moment(first[i], "HH:mm");
        var end = s.add(duration, period);
        var slot = 0;
        for (let j = 0; j < this.booking.length; j++) {
          var bookingStart = moment(this.booking[j].selected_time, "HH:mm");
          var bookings = moment(this.booking[j].selected_time, "HH:mm");
          var bookingEnd = bookings.add(this.booking[j].duration, period);

          if (bookingStart >= start && bookingStart < end) {
            slot += 1;
            continue;
          }
          if (bookingEnd > start && bookingEnd <= end) {
            slot += 1;
            continue;
          }
          if (bookingStart < start && bookingEnd > end) {
            slot += 1;
            continue;
          }
        }

        if (slot < this.slot) {
          this.timesPlusDuration.push(start.format("HH:mm"));
        }
      }
      console.log;
    },
    getAllServiceForEdit() {
      const params = new URLSearchParams();
      params.append("findAllService", "done");
      params.append("branch_id", this.editBranch);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.serviceItem = response.data.service;
            this.getServiceInfo();
          } else {
            console.log("no service found");
            this.serviceItem = [];
            this.editService = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllProviderForEdit() {
      const params = new URLSearchParams();
      params.append("getAllProvider", "done");
      params.append("service_id", this.editService);

      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.providerItem = response.data.provider;
            this.getSelectedProviderForEdit();
          } else {
            console.log("no provider found");
            this.providerItem = [];
            this.editProvider = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedProviderForEdit() {
      for (var i = 0; i < this.providerItem.length; i++) {
        if (this.providerItem[i].provider_id == this.editProvider) {
          this.providerWorkDay = JSON.parse(this.providerItem[i].work_day);
          this.providerWorkTime = JSON.parse(this.providerItem[i].work_time);
          this.providerBreakTime = JSON.parse(this.providerItem[i].break_time);
          this.slot = JSON.parse(this.providerItem[i].slot);
        }
      }
    },
    getServiceInfo() {
      for (var i = 0; i < this.serviceItem.length; i++) {
        if (this.serviceItem[i].service_id == this.editService) {
          this.serviceDuration = this.serviceItem[i].duration;
          this.serviceID = this.serviceItem[i].service_id;
          this.serviceTitle = this.serviceItem[i].title;
          this.serviceDescription = this.serviceItem[i].description;
          this.getBooking();
        }
      }
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    updateCustomerList(v) {
      this.customer = v;
    },
  },
};
</script>
<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.overflow {
  flex-grow: 100;
  overflow: auto;
}
.v-text-field__details {
  margin-top: 2px;
}
</style>
